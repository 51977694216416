.videogall {
  &__item {
    @include col(1);
    margin-bottom: 20px;
    @include r(sm) {
      @include col(1/2); }
    @include r(lg) {
      @include col(1/3); } }
  &__link {
    text-decoration: none;
    color: $c-text;
    font-weight: bold;
    font-size: 14px; }
  &__image {
    position: relative;
    overflow: hidden;
    padding-top: 54%;
    margin-bottom: 10px;
    img {
      position: absolute;
      top: -18%;
      left: 0;
      width: 100%; } } }

.video-sign {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: translate .3s;
    width: 60px;
    height: 60px;
    background: $c-brand url('/assets/tpl/img/play.svg') no-repeat 60% 50%;
    background-size: 40%;
    border-radius: 50%; } }

.article .video-sign {
  display: inline-block; }

.photogall {
  @include row();
  &__item {
    @include col(1);
    margin-bottom: 20px;
    @include r(sm) {
      @include col(1/2); }
    @include r(lg) {
      @include col(1/3); } } }

.taglist {
  margin-bottom: 30px; }

.tag-item {
  display: inline-block;
  margin-right: 5px;
  padding: .5em 1em;
  text-decoration: none;
  background-color: $c-brand;
  border-radius: 10px;
  color: #000;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  &.active {
    background-color: $c-link;
    color: #fff; } }

.gall-teacher {
  display: flex;
  // grid-template-columns: repeat(5, 1fr)
  // grid-gap: 10px
  margin-top: $l-g;
  &__item {
    margin: 5px; } }

.article-gall {
  display: grid;
  margin-top: $l-g;
  grid-gap: 10px;
  grid-template-columns: repeat(4, auto);
  &__item {
    margin: 5px; } }
