@mixin hc-hamburger($type: 'default', $width: $hc-offcanvas-nav-trigger-width, $height: $hc-offcanvas-nav-trigger-height, $line-width: $hc-offcanvas-nav-trigger-line-width, $color: $hc-offcanvas-nav-trigger-color, $transform-speed: $hc-offcanvas-nav-trigger-transform-speed) {
  position: absolute;
  cursor: pointer;
  user-select: none;
  display: none;
  top: 20px;
  z-index: 9980;
  width: $width;
  min-height: $height;

  $line-offset: ($height - $line-width) / 2;

  span {
    width: $width;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: 50% 50%;

    &,
    &::before,
    &::after {
      display: block;
      position: absolute;
      left: 0;
      height: $line-width;
      background: $color;
      transition: all $transform-speed ease;
    }

    &::before,
    &::after {
      content: '';
      width: 100%;
    }

    &::before {
      top: -$line-offset;
    }

    &::after {
      bottom: -$line-offset;
    }
  }

  &.toggle-open {

    span {
      background: rgba(0, 0, 0, 0);
      transform: rotate(45deg);

      &::before {
        transform: translate3d(0, $line-offset, 0);
      }

      &::after {
        transform: rotate(-90deg) translate3d($line-offset, 0, 0);
      }
    }
  }
}
