* {
  box-sizing: border-box;
  min-width: 0; }

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i,800&display=swap&subset=cyrillic');

@import "mixin/**/*";
@import "_var";
@import 'normalize.css';

@import "_sprite-symb";
@import "base/_base";
@import "base/_helper";
@import "vendor/_grid-m";
@import "_plugin";
// @import "vendor/_jquery.formstyler.scss"

@import "block/**/*";
