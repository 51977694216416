.soc-list {
  display: flex;
  align-items: center;
  &__item {
    display: inline-block;
    margin: 0 .25em;
    color: inherit; }
  &--navbar {
    font-size: 1.5em;
    margin-left: auto;
    color: #FFF;
    .soc-list__item:hover {
      color: $c-brand; } } }

.intro-slide {
  background: linear-gradient(90deg, $c-main 0%, #302f2e 26%, #302f2e 80%, $c-main 100%);
  overflow: hidden;
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 40px;
      left: 20px;
      right: 20px;
      bottom: 40px;
      border: 3px solid $c-brand; } }
  &__txt {
    margin: auto -2em auto 5vw;
    flex: 1 1 auto;
    @include rmax(sm) {
      margin-top: 40px;
      margin-right: -2em;
      align-self: flex-start; } }
  &__title {
    font-family: $f-title;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 36px;
    color: #767b7e;
    line-height: 1;
    span {
      display: block;
      &:nth-child(even) {
        margin-left: 1.5em;
 } }        // text-align: right
    @include r(sm) {
      font-size: 60px; }
    @include r(md) {
      font-size: 100px; }
    @include r(xl) {
      font-size: 120px; } }
  &__btn-getform {
    @include rmax(sm) {
      position: absolute;
      z-index: 100;
      bottom: 20px;
      left: 50%;
      width: 70%;
      transform: translateX(-50%); } }
  &__img {
    align-self: flex-end;
    position: relative;
    left: 10%;
    flex: 0 0 70%;
    z-index: 2;
    @include r(md) {
      flex: 0 0 50%;
      left: 0; }
    @include r(xl) {
      flex-basis: auto; } } }


.teacher-card {
  @include col(1);
  padding: 20px;
  border: 1px solid $c-brand;
  border-radius: 10px;
  margin-bottom: 20px;
  @include r(sm) {
    display: flex;
    flex-direction: row; }
  @include r(lg) {
    @include col(1/2);
    margin-bottom: 0; }
  &__photo {
    min-width: 180px;
    margin-right: $l-g;
    @include rmax(md) {
      height: 300px;
      margin-bottom: 20px;
      img {
        object-fit: scale-down; } } }
  &__name {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px; }
  &__desc {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px; }
  &__txt {
    font-size: 14px; } }

.tranning {
  @include row();
  &__item {
    @include col(1);
    margin-bottom: $l-g;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    background-color: #def0f2;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    @include r(sm) {
      @include col(1/2); }
    @include r(lg) {
      @include col(1/3); } }
  &__header {
    color: $c-sec;
    margin-bottom: 10px; }
  &__title {
    line-height: 1;
    font-weight: bold;
    font-size: 24px; }
  &__teacher-img {
    border-radius: 50%;
    margin-bottom: 10px; }
  &__teacher-block {
    margin-bottom: 10px;
    p {
      margin: 0; } }
  &__teacher-name {
    font-size: 18px;
    font-weight: bold; }
  &__date {
    background-color: $c-sec;
    color: #fff;
    margin: auto -20px -20px;
    padding: 10px 20px;
    font-size: 16px; } }

.gallery-widget {
  padding: 30px 0;
  background-color: $c-main;
  &__wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 200px;
    @include r(md) {
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: 150px 150px; } }

  // +r(sm)
  &__item:nth-of-type(1),
  &__item:nth-of-type(3) {
    grid-row: span 2;
    grid-column: span 2; } }


.contact {
  &__cnt {
    @include col(1/4);
    @include rmax(md) {
      @include col(1);
      margin-bottom: 20px; } }
  &__map {
    @include col(3/4);
    @include rmax(md) {
      @include col(1); } } }
