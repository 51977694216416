.error {
  box-shadow: 0 0 10px #c32f2f;
  border: 1px solid #c32f2f; }

.verify {
  display: none; }

.btn-wrap {
  margin-top: 30px;
  min-width: 100%;
  &--center {
    text-align: center; }
  &--right {
    text-align: right; } }
.btn {
  display: inline-block;
  position: relative;
  overflow: hidden;
  display: inline-block;
  padding: .5em 2em;
  background: $c-brand;
  color: $c-text;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  border-radius: .5em;
  border: 0;
  cursor: pointer;
  &:before {
    content: "";
    height: 100%;
    width: 10%;
    background-color: rgba(#fff, .3);
    position: absolute;
    top: 0;
    left: 0;
    transform: skew(10deg) translateX(-110%);
    transition: transform .4s ease-out; }
  &:hover:before {
    transform: skew(10deg) translateX(1010%); }
  &--big {
    @include r(md) {
      font-size: 24px; } } }

.form__line {}
.form__line textarea {}

.form {
  &__title {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 22px;
    text-align: center;
    margin-bottom: 20px;
    color: $c-brand; }
  &__line {
    margin-bottom: 20px;
    input,
    textarea {
      padding: 10px;
      font-size: 16px; } }

  &--full {
    .form__line input,
    .form__line textarea {
      width: 100%; } }

  &--label-line {
    max-width: 800px;
    margin: 20px auto;
    .form__line {
      label {
        display: block;
        width: 100%;
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: bold;
        color: #606873; }
      input,
      textarea {
        width: 100%;
        border-color: #adacac;
        border-width: 0 0 2px 0;
        &:focus {
          outline: none;
          border-color: $c-brand; } } } } }


.modalform {
  display: none;
  min-width: 30%;
  max-width: 90%;
  background: linear-gradient(0deg, rgba(144, 224, 236, 0.5) 0%, rgba(235, 246, 251, 0.1) 100%);
  .form-title {
    color: #fff; } }


.form-sender {
  .form__line {
    display: flex; }
  input[type="email"] {
    padding-right: 15px;
    margin-right: -5px; } }
