.teacher {
  &__item {
    @include row();
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4*$l-g; }
  &__img {
    padding: 0 60px;
    margin-bottom: 20px;
    @include col(1);
    @include r(md) {
      @include col(4/12); } }
  &__cnt {
    @include col(1);
    @include r(md) {
      @include col(8/12); } }
  &__header {
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid $c-sec; }
  &__title {
    margin: 0 0 10px;
    line-height: 1;
    color: $c-brand;
    font-size: 36px;
    font-weight: bold; }
  &__subtitle {
    font-size: 18px;
    font-weight: bold;
    margin: 0; }
  &__txt {
    @include r(md) {
      columns: 2;
      column-gap: 2*$l-g; } } }

.dance-style-list {
  display: flex;
  flex-wrap: wrap;
  &__item {
    @include col(1);
    position: relative;
    @include r(sm) {
      @include col(1/2); }
    @include r(md) {
      @include col(1/4); }
    &:before {
      content: "";
      position: absolute;
      opacity: 0;
      transition: opacity .2s;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(#000, .5); } }
  &__name {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 60px 20px 20px;
    width: 100%;
    text-align: center;
    line-height: 1;
    color: #fff;
    transition: color .3s;
    background: linear-gradient(0deg, rgba(0,0,0,.7) 0%, rgba(0,0,0,0) 100%);
    font-size: 48px;
    font-weight: bold; }
  &__intro {
    opacity: 0;
    position: absolute;
    transition: opacity .5s;
    top: 30px;
    left: 0;
    width: 100%;
    padding: 20px;
    color: #fff;
    text-shadow: 0 0 10px #000;
    text-align: center;
    text-decoration: none;
    font-weight: bold; } }

.dance-style-list__item:hover {
  box-shadow: 0 0 10px $c-brand;
  &:before {
    opacity: 1; }
  .dance-style-list__intro {
    opacity: 1; }
  .dance-style-list__name {
    color: $c-brand; } }
