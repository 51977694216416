.dance-style {
  @include r(lg) {
    &__slider-nav {
      &:hover .dance-style__slider-nav__item:not(:hover) {
        opacity: .3; } } }
  &--home {
    background-color: #e8f3f5;
    overflow: hidden; }
  &__slider-item {
    display: flex;
    @include rmax(md) {
      flex-direction: column; } }
  &__img {
    flex: 1 0 40%;
    margin-right: $l-g;
    margin-bottom: 30PX;
    @include r(md) {
      flex: 1 0 30%;
      margin-bottom: 0; } }
  &__slider-nav-wrap {
    background-color: $c-main;
    padding: 30px 0; }
  &__slider-nav__item {
    position: relative;
    cursor: pointer;
    margin: 10px;
    transition: opacity .3s;
    @include rmax(sm) {
      max-width: 250px; }
    &.slick-current {
      box-shadow: 0 0 10px rgba($c-brand, 1); } }
  &__style-name {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px 10px;
    color: $c-text;
    background-color: $c-brand; }
  &__getform {
    margin-top: 30px; } }
