.menu__list {
  padding: 0;
  margin: 0;
  list-style: none; }

.menu__link {
  position: relative;
  padding: 5px 0;
  display: block;
  white-space: nowrap;
  text-decoration: none;
  color: $c-brand;
  &:hover {
    color: inherit; } }

.main-menu--desktop {
  .menu__list,
  .menu__sublist {
    padding: 0;
    margin: 0 -10px; }

  .menu__item {
    display: inline-block;
    padding: 0 10px;
    position: relative;
    & > .menu__link {
      text-transform: uppercase;
      font-weight: 800;
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        width: 0;
        background-color: #000;
        transition: width .2s ease-out; } }
    &.active > .menu__link {
      color: $c-brand; } }


  .menu__sublist {
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 0 15px rgba(#000, .2);
    position: absolute;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transform: translateY(50px);
    transition: all .35s ease-out .3s;
    .menu__link:hover {
      color: $c-brand; } }

  .menu__item:hover {
    .menu__link:before {
      width: 100%; }

    .menu__sublist {
      pointer-events: auto;
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0s; } } }

.hc-nav-trigger {
  position: relative;
  top: 0;
  @include r(sm) {
    margin-right: auto; } }

