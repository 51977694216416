.article-wrap {
  margin-top: 60px;
  align-items: flex-start;
  .title {
    margin: 0 0 30px; } }

.article h2:first-child,
.article h3:first-child {
  margin-top: 0; }

.article {
  @include col(1);
  @include r(xl) {
    @include col(9/12); }
  table {
    width: 100%;
    display: block;
    overflow-x: auto;
    margin-bottom: 20px;
    border-collapse: collapse;
    p {
      margin: 0; }
    thead th {
      padding: 10px;
      background-color: $c-sec;
      border: 1px solid;
      color: #fff; }
    td {
      padding: 1em;
      border: 1px solid gray; } } }

.article__side {
  @include col(3/12);
  @include rmax(xl) {
    display: none; } }

.widget {
  background: #e1eaef;
  padding: 20px;

  &__item {
    &:not(:first-child) {
      margin-top: 60px; } } }

.news-side {
  display: block;
  color: $c-text;
  text-decoration: none;
  &:not(:first-child) {
    margin-top: 30px; }
  &__header {
    display: flex;
    margin-bottom: 10px;
    align-items: flex-end; }
  &__date {
    position: relative;
    text-decoration: none;
    font-size: 22px;
    margin-left: 10px;
    font-weight: bold;
    text-align: center;
    flex: 1;
    padding: 10px;
    border: 2px solid $c-brand;
    span {
      font-size: .7em;
      display: block; } }
  &__title {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 18px;
    color: $c-link; } }

.editor-photo4 {
  display: grid;
  grid-gap: 10px;
  @include r(md) {
    grid-template-columns: 75% 25%;
    grid-auto-rows: 150px;
    // grid-auto-flow: dense
    *:first-child {
      grid-row: span 3; }
    img {
      max-width: none;
      object-fit: cover;
      width: 100%;
      height: 100%; } } }

.editor-photo3 {
  grid-gap: 10px;
  display: grid;
  @include r(md) {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 250px;
    img {
      max-width: none;
      object-fit: cover;
      width: 100%;
      height: 100%; } } }

.editor-photo2 {
  grid-gap: 10px;
  display: grid;
  @include r(md) {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 250px;
    img {
      max-width: none;
      object-fit: cover;
      width: 100%;
      height: 100%; } } }

