$breakpoints: (
  'xs': 420px,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
  'xxl': 1400px
) !default;

$f-base: 16px/1.5 "Open Sans", sans-serif;
$f-title: "Times New Roman", serif;
$f-color: #555;

$l-g: 30px;

$c-main: #1a1c1e;
$c-brand: #38cae0;
$c-sec: #045c9f;
$c-text: #000;
$c-link: #1b82ea;
$c-active: inherit;


// @include font("Open Sans", "opensans-regular-webfont", normal );
// @include font("Open Sanss", "opensans-italic-webfont", normal, italic);
// @include font("Open Sans", "opensans-bold-webfont", bold );
// @include font("Open Sanss", "opensans-bolditalic-webfont", bold, italic);
// @include font("Open Sans", "opensans-extrabold-webfont", 800 );

