
.page {
  display: flex;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  flex-direction: column;

  &__content {
    flex: 1 0 auto; } }

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
  padding: 0 20px;
  @include r(sm) {
    padding: 0 30px; } }

.row {
  @include row(); }

.section {
  margin-bottom: $l-g*4; }

.logo {
  &--navbar {
    width: 100px;
    margin-right: auto; } }

.title {
  font: 400 48px/1 $f-title;
  text-transform: uppercase;
  margin: 1.5em 0 1em;
  border-bottom: 2px solid $c-brand;
  @include rmax(sm) {
    font-size: 36px; }
  @include rmax(xs) {
    font-size: 28px; } }

.small-title {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 10px; }

.widget__title {
  margin: 0 0 20px;
  text-transform: uppercase;
  text-align: left;
  font-size: 18px; }

.more-link {
  font-weight: bold; }

.breadcrumbs {
  list-style: none;
  margin: 20px 0 20px;
  padding: 0;
  &__item {
    display: inline-block;
    font-size: 13px;
    font-weight: bold;
    a {
      transition: color .2s; }
    &:not(.active):after {
      content: '•';
      display: inline-block;
      margin: 0 5px;
      vertical-align: middle; }
    &:hover {
      a {
        color: $c-brand; } } } }

.pagination {
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
  .page-item,
  &__page {
    vertical-align: middle;
    margin: 0 5px;
    display: inline-block;
    a {
      color: inherit;
      text-decoration: none; } }
  &__page.active {
    color: #fff;
    background-color: $c-main; }
  &__page {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #afe8f1;
    border-radius: 50%;
    font-weight: bold;
    color: #000; }
  .page-item:nth-child(2),
  .pagination__page + .page-item {
    margin: -5px 10px 0;
    font-size: 30px;
    line-height: 20px; }
  .page-item:not(.disabled):hover a {
    color: $c-brand; }
  .disabled {
    corsor: default;
    pointer-events: none;
    opacity: .5; } }

.footer-wrap {
  background-color: $c-main;
  color: #ced8e2; }

.footer {
  @include row();
  padding: 30px 0;
  align-items: flex-start;

  &__about {
    @include col(1);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
    @include r(md) {
      @include col(1/4); } }
  &__about-contact {
    width: 100%;
    display: grid;
    gap: 10px; }
  &__logo {
    width: 100px;
    display: inline-block;
    margin-bottom: 20px;
    margin-right: 30px; }

  &__menu {
    @include col(1);
    margin-bottom: 20px;
    @include r(md) {
      @include col(2/4); }
    .main-menu {
      max-width: 500px;
      columns: 2 30px; }
    .menu__link {
      display: inline-block; } }

  &__sender {
    @include col(1);
    @include r(lg) {
      @include col(1/4); } } }

.tel {
  text-decoration: none;
  color: inherit;
  transition: all .3s;
  &:hover {
    color: $c-brand; } }

.messenger {
  display: flex;
  align-items: center;
  gap: 15px;
  line-height: 1;
  font-size: 1px;
  &__item {
    text-decoration: none;
    transition: all .3s;
    &:hover {
      transform: scale(1.1); }
    .svg-icon {
      font-size: 32px; } }

  &--footer {
    .svg-icon {
      font-size: 28px; } } }

.info-contact {
  text-align: center;
  font-weight: bold;
  color: #fff;
  .tel {
    font-size: 24px; }
  .messenger {
    justify-content: center; } }
