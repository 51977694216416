.page__navbar {
  background-color: $c-main;
  padding: 10px 0;
  color: #fff; }

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @include rmax(sm) {
    flex-wrap: wrap;
    font-size: 14px; }
  &__side {
    @include rmax(sm) {
      flex: 0 0 100%; } }

  &__logo {
    margin-right: auto; }
  &__contact {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    gap: 20px; }
  &__addr {
    display: none;
    @include r(xl) {
      display: block; } } }
