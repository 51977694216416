.news-list {
  @include row();
  justify-content: center;
  &__item {
    @include col(1);
    flex-wrap: wrap;
    margin-bottom: 20px;
    @include r(xs) {
      flex-wrap: nowrap; }
    @include r(lg) {
      @include col(1/2); } } }

.news {
  display: flex;
  text-decoration: none;
  color: $c-text;

  &:hover {
    .news__cnt {
      background-color: rgba($c-brand, .15); } }
  &__img {
    width: 80%;
    margin: auto;
    @include r(xs) {
      margin: 0;
      width: 50%; } }
  &__cnt {
    padding: 60px 20px 20px;
    margin-top: -40px;
    border: 2px solid $c-brand;
    transition: background .3s;
    @include r(xs) {
      flex: 0 0 70%;
      margin: 20px 0 20px -10%;
      padding: 20px 20px 20px calc(10% + 20px); } }
  &__date {
    font: bold 36px/1 $f-title;
    margin-bottom: 20px;
    span {
      display: block;
      margin-left: 50px;
      font-size: .6em; } }
  &__title {
    margin-bottom: 20px;
    font-weight: 800;
    font-size: 18px;
    color: $c-link; } }
