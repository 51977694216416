.svg-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.icon-facebook {
  font-size:(24/10)*1rem;
  width:(24/24)*1em;
}
.icon-map {
  font-size:(512/10)*1rem;
  width:(512/512)*1em;
}
.icon-skype {
  font-size:(24.3/10)*1rem;
  width:(24/24.3)*1em;
}
.icon-telegram {
  font-size:(24/10)*1rem;
  width:(24/24)*1em;
}
.icon-viber {
  font-size:(25.9/10)*1rem;
  width:(24/25.9)*1em;
}
.icon-vk {
  font-size:(24/10)*1rem;
  width:(24/24)*1em;
}
